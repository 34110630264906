@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.gradient-symmetry {
  background-color: #147a4b;
  background-image: linear-gradient(160deg, rgba(172, 255, 216, 0.6) 60%, rgba(208, 255, 238, 0.6) 100%); }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f4f3ef00;
  border-radius: 10px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(325deg, #4f58b700 0, rgba(249, 250, 253, 0) 0%);
  border-radius: 10px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(31, 38, 135, 0); }

::-webkit-scrollbar {
  width: 0.2px;
  height: 1px; }

label {
  font-size: 12px;
  color: black;
  font-family: "Inter", "sans-serif"; }

.big-title-input > textarea {
  font-size: 24px !important;
  border-bottom: #b3b3b3; }

.yoda-animation {
  transform: translateY(0px);
  animation: float 6s ease-in-out infinite; }

/*Cube*/
.animated-cube {
  animation: hue-rotate 3s linear infinite; }

@keyframes hue-rotate {
  from {
    -webkit-filter: hue-rotate(0);
    -moz-filter: hue-rotate(0);
    -ms-filter: hue-rotate(0);
    filter: hue-rotate(0); }
  to {
    -webkit-filter: hue-rotate(360deg);
    -moz-filter: hue-rotate(360deg);
    -ms-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg); } }

/* Logo*/
.otp-login-container .title-font {
  font-family: Poppins;
  font-weight: 700;
  color: #f2c0f2; }

.otp-login-container .caption-font {
  font-family: inter;
  font-size: 2em;
  font-weight: 700;
  color: white; }

.otp-login-container form {
  padding: 25px;
  margin-right: auto;
  margin-left: auto;
  max-width: 23.75rem;
  background-color: #c6f8f9;
  border-radius: 3px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  font-family: inter; }

.otp-login-container input,
.otp-login-container textarea {
  display: block;
  padding: 0.5rem 0.75rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #eee;
  border-left: 0;
  border-radius: 3px;
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1); }

.otp-login-container input:focus,
.otp-login-container textarea:focus {
  outline: 0;
  border-bottom-color: #f2c0f2; }

.otp-login-container textarea {
  resize: vertical; }

.otp-login-container .btn {
  display: inline-block;
  padding: 0.75rem 1rem;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
  min-width: 160px; }

.otp-login-container .btn:focus,
.otp-login-container .btn:hover {
  /* box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07); */
  background-color: #083d4f !important;
  color: #c6f8f9 !important; }

.otp-login-container .btn:focus {
  outline: 0; }

.otp-login-container body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.otp-login-container .poppins {
  font-family: "Poppins", sans-serif; }

.otp-login-container .inter {
  font-family: "Inter", sans-serif; }

.purple-bg {
  background: linear-gradient(252.44deg, #4e1882 0%, #300964 100%), #9929ea;
  background-size: cover; }

.indigo-bg {
  background-image: linear-gradient(235deg, #4f57b7 0, #232968 50%, #1c2135 100%);
  background-size: cover; }

.otp-login-container .font-purple {
  color: #4e1882; }

.otp-login-container .light-purple-bg {
  background-color: #f2c0f2; }

.border-chronos {
  border-color: rgba(221, 214, 254, 0.15) !important; }

/* Form */
.glass-form {
  background: rgba(195, 206, 244, 0.15) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  -moz-backdrop-filter: blur(5px) !important;
  border-radius: 10px !important;
  border: 1px solid rgba(195, 206, 244, 0.15) !important; }

.glass-modal-header {
  background: rgba(195, 206, 244, 0.25) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  -moz-backdrop-filter: blur(5px) !important; }

/*Theme Colors*/
/*Borders*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Borders*/
/*Responsiveness*/
/*Theme Colors*/
/*Borders*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Borders*/
.character-card-container {
  border-radius: 10px;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.07); }

.upper-character-card {
  border-radius: 10px 10px 0px 0px; }

.upper-character-card-h1 {
  line-height: 1.05875rem; }

.character-card-li {
  line-height: 1.0625rem; }

.character-card-li-edit {
  margin-left: 20px;
  height: 17px;
  min-width: 10px;
  max-width: 10px; }

.character-card-li-icon {
  height: 17px;
  min-width: 10.31px; }

/*Theme Colors*/
/*Borders*/
/*Borders*/
.content-offering-font {
  font-size: 10px; }

.components-modals-marketing-label {
  color: #747474 !important; }

.content-idea-card {
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.2); }

.user-select-card-selected {
  background: #e9ebff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px; }

.components-modals-multi-checkcircleicon {
  color: #58ea89 !important; }

/* ------------------ React Big Calendar ------------------------ */
.rbc-calendar {
  background: #ffffff;
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  font-family: "Poppins", sans-serif; }

.rbc-time-content {
  overflow: visible !important; }

.rbc-event-label {
  display: none !important; }

.rbc-time-slot {
  border: none !important; }

.rbc-event {
  background-color: #f8f8f8 !important;
  padding: 0px !important;
  border: none !important;
  overflow: visible !important;
  position: relative !important; }

.rbc-events-container {
  border-color: #f8f8f8 !important; }

.rbc-timeslot-group {
  border-color: #f8f8f8 !important; }

.rbc-day-bg {
  border-color: #f8f8f8 !important; }

.rbc-month-view {
  border-color: #f8f8f8 !important;
  margin-top: 14px !important; }

.rbc-month-row {
  border-color: #f8f8f8 !important; }

.rbc-header {
  border-color: #f8f8f8 !important; }

.dropdown {
  margin: 0px !important;
  height: fit-content; }

.dropdown-toggle {
  padding: 0px !important;
  background-color: transparent !important;
  margin: 0px !important;
  color: #323232; }

.dropdown-menu:hover {
  background-color: transparent !important; }

.show > .btn-primary.dropdown-toggle {
  background-color: transparent !important;
  color: #323232; }

.dropdown-toggle:after {
  display: none !important; }

.rbc-header > span {
  font-weight: 700 !important;
  font-family: "Inter", sans-serif; }

.rbc-time-view {
  margin-top: -28px !important;
  border-color: #f8f8f8 !important;
  padding-right: 12px !important; }

.rbc-allday-cell {
  display: none !important; }

.rbc-label {
  color: #747474;
  font-family: "Poppins", sans-serif; }

.react-calendar {
  display: flex !important;
  flex-direction: column !important; }

.react-calendar__navigation {
  display: flex !important;
  align-items: center !important;
  padding-bottom: 20px !important; }

.react-calendar__navigation__arrow {
  margin-top: -6px !important; }

.components-modals-marketing-index-div {
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.07);
  border-radius: 25px; }

.content-offering-font {
  font-size: 10px; }

.components-modals-marketing-label {
  color: #747474 !important; }

.content-idea-card {
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.2); }

.rbc-off-range-bg {
  background-color: #f1f1f1 !important; }

.v2-view-para {
  color: #532a01; }

.v2-bg-active {
  background-color: #1e803f;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.11); }

.contentcreate-div1 {
  background: #f8f8f8;
  box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 0.07);
  border-radius: 25px !important; }

.cards-content-div {
  border-radius: 25px 25px 0px 0px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  background: #002739;
  box-shadow: 0px 10px 20px -10px rgba(73, 81, 151, 0.5); }
  .cards-content-div:disabled {
    color: #e1e1e1; }
  .cards-content-div:hover {
    font-size: 15px;
    line-height: 22px; }
  .cards-content-div:active {
    border: 2px solid #002739;
    font-size: 15px;
    line-height: 22px; }
  .cards-content-div:disabled {
    border: 1px solid #f4f4f4;
    background: #f4f4f4;
    box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 0.25); }

.cards-content-h1 {
  color: #caba2c; }

.cards-content-h1-2 {
  color: #1e7f3f; }

.cards-content-h1-3 {
  color: #532a01; }

.show-edit {
  opacity: 1;
  -webkit-animation: reveal 1s ease-in-out;
  -moz-animation: reveal 1s ease-in-out;
  -ms-animation: reveal 1s ease-in-out;
  -o-animation: reveal 1s ease-in-out;
  animation: reveal 1s ease-in-out; }

.hide-edit {
  opacity: 0;
  -webkit-animation: hide 4s ease-in-out;
  -moz-animation: hide 4s ease-in-out;
  -ms-animation: hide 4s ease-in-out;
  -o-animation: hide 4s ease-in-out;
  animation: hide 4s ease-in-out; }

.edit-box {
  width: 100%;
  height: 0px;
  min-height: 0px;
  transition: all 0.5s ease-in-out; }

.edit-box-expanded {
  width: 100%;
  height: 40px;
  min-height: 40px;
  transition: all 0.5s ease-in-out; }

.edit-slide-in {
  opacity: 1;
  -webkit-animation: slideFromRight 1s ease;
  -moz-animation: slideFromRight 1s ease;
  -ms-animation: slideFromRight 1s ease;
  -o-animation: slideFromRight 1s ease;
  animation: slideFromRight 1s ease; }

.edit-slide-out {
  opacity: 1;
  -webkit-animation: slideOutRight 1s ease;
  -moz-animation: slideOutRight 1s ease;
  -ms-animation: slideOutRight 1s ease;
  -o-animation: slideOutRight 1s ease;
  animation: slideOutRight 1s ease; }

@-webkit-keyframes slideFromRight {
  0% {
    opacity: 0;
    transform: translateX(20px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-moz-keyframes slideFromRight {
  0% {
    opacity: 0;
    transform: translateX(20px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-ms-keyframes slideFromRight {
  0% {
    opacity: 0;
    transform: translateX(20px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-o-keyframes slideFromRight {
  0% {
    opacity: 0;
    transform: translateX(20px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slideFromRight {
  0% {
    opacity: 0;
    transform: translateX(20px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-webkit-keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 0;
    transform: translateX(30px); } }

@-moz-keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 0;
    transform: translateX(30px); } }

@-ms-keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 0;
    transform: translateX(30px); } }

@-o-keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 0;
    transform: translateX(30px); } }

@keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 0;
    transform: translateX(30px); } }

/*Theme Colors*/
/*Borders*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Borders*/
/*Responsiveness*/
/*Theme Colors*/
/*Borders*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Borders*/
/* Main Theme Colors*/
.font-indigo-10 {
  color: #f6f8ff; }

.font-indigo-50 {
  color: #ebedf7; }

.font-indigo-100 {
  color: #f0f1f8; }

.font-indigo-200 {
  color: #e8ebff; }

.font-indigo-250 {
  color: #a4abcb; }

.font-indigo-300 {
  color: #8da1fc; }

.font-indigo-400 {
  color: #6775f5; }

.font-indigo-550 {
  color: #4f57b7; }

.font-indigo-650 {
  color: #1e2447; }

.font-indigo-700 {
  color: #384486; }

.bg-indigo-10 {
  background-color: #f6f8ff; }

.bg-indigo-50 {
  background-color: #ebedf7; }

.bg-indigo-100 {
  background-color: #f0f1f8; }

.bg-indigo-200 {
  background-color: #e8ebff; }

.bg-indigo-250 {
  background-color: #a4abcb; }

.bg-indigo-300 {
  background-color: #8da1fc; }

.bg-indigo-400 {
  background-color: #6775f5; }

.bg-indigo-550 {
  background-color: #4f57b7; }

.bg-indigo-650 {
  background-color: #1e2447; }

.bg-indigo-700 {
  background-color: #384486; }

.border-indigo-10 {
  border-color: #f6f8ff; }

.border-indigo-50 {
  border-color: #ebedf7; }

.border-indigo-100 {
  border-color: #f0f1f8; }

.border-indigo-200 {
  border-color: #e8ebff; }

.border-indigo-250 {
  border-color: #a4abcb; }

.border-indigo-300 {
  border-color: #8da1fc; }

.border-indigo-400 {
  border-color: #6775f5; }

.border-indigo-550 {
  border-color: #4f57b7; }

.border-indigo-650 {
  border-color: #1e2447; }

.border-indigo-700 {
  border-color: #384486; }

.font-gray-50 {
  color: #f8f8f9; }

.font-gray-60 {
  color: #f1f1f1; }

.font-gray-100 {
  color: #f4f4f4; }

.font-gray-150 {
  color: #e1e1e1; }

.font-gray-200 {
  color: #d8d8d8; }

.font-gray-250 {
  color: #a4a2a5; }

.font-gray-300 {
  color: #6d6d6d; }

.font-gray-350 {
  color: #747474; }

.font-gray-400 {
  color: #424242; }

.font-gray-500 {
  color: #332e35; }

.font-gray-600 {
  color: #323232; }

.font-gray-800 {
  color: #2b2b2d; }

.font-gray-900 {
  color: #363430; }

.font-gray-1000 {
  color: #171718; }

.bg-gray-50 {
  background-color: #f8f8f9; }

.bg-gray-60 {
  background-color: #f1f1f1; }

.bg-gray-100 {
  background-color: #f4f4f4; }

.bg-gray-150 {
  background-color: #e1e1e1; }

.bg-gray-200 {
  background-color: #d8d8d8; }

.bg-gray-250 {
  background-color: #a4a2a5; }

.bg-gray-300 {
  background-color: #6d6d6d; }

.bg-gray-350 {
  background-color: #747474; }

.bg-gray-400 {
  background-color: #424242; }

.bg-gray-500 {
  background-color: #332e35; }

.bg-gray-600 {
  background-color: #323232; }

.bg-gray-800 {
  background-color: #2b2b2d; }

.bg-gray-900 {
  background-color: #363430; }

.bg-gray-1000 {
  background-color: #171718; }

.border-gray-50 {
  border-color: #f8f8f9; }

.border-gray-60 {
  border-color: #f1f1f1; }

.border-gray-100 {
  border-color: #f4f4f4; }

.border-gray-150 {
  border-color: #e1e1e1; }

.border-gray-200 {
  border-color: #d8d8d8; }

.border-gray-250 {
  border-color: #a4a2a5; }

.border-gray-300 {
  border-color: #6d6d6d; }

.border-gray-350 {
  border-color: #747474; }

.border-gray-400 {
  border-color: #424242; }

.border-gray-500 {
  border-color: #332e35; }

.border-gray-600 {
  border-color: #323232; }

.border-gray-800 {
  border-color: #2b2b2d; }

.border-gray-900 {
  border-color: #363430; }

.border-gray-1000 {
  border-color: #171718; }

.font-yellow-50 {
  color: #fef6e9; }

.font-yellow-100 {
  color: #fde4be; }

.font-yellow-500 {
  color: #f7cd16; }

.font-yellow-550 {
  color: #f1c21b; }

.font-yellow-600 {
  color: #e0b808; }

.font-yellow-700 {
  color: #6b5f1c; }

.font-yellow-750 {
  color: #5b511b; }

.font-yellow-800 {
  color: #ba7507; }

.font-yellow-850 {
  color: #935d05; }

.font-yellow-880 {
  color: #865931; }

.font-yellow-900 {
  color: #532a01; }

.font-yellow-1000 {
  color: #301709; }

.bg-yellow-50 {
  background-color: #fef6e9; }

.bg-yellow-100 {
  background-color: #fde4be; }

.bg-yellow-500 {
  background-color: #f7cd16; }

.bg-yellow-550 {
  background-color: #f1c21b; }

.bg-yellow-600 {
  background-color: #e0b808; }

.bg-yellow-700 {
  background-color: #6b5f1c; }

.bg-yellow-750 {
  background-color: #5b511b; }

.bg-yellow-800 {
  background-color: #ba7507; }

.bg-yellow-850 {
  background-color: #935d05; }

.bg-yellow-880 {
  background-color: #865931; }

.bg-yellow-900 {
  background-color: #532a01; }

.bg-yellow-1000 {
  background-color: #301709; }

.border-yellow-50 {
  border-color: #fef6e9; }

.border-yellow-100 {
  border-color: #fde4be; }

.border-yellow-500 {
  border-color: #f7cd16; }

.border-yellow-550 {
  border-color: #f1c21b; }

.border-yellow-600 {
  border-color: #e0b808; }

.border-yellow-700 {
  border-color: #6b5f1c; }

.border-yellow-750 {
  border-color: #5b511b; }

.border-yellow-800 {
  border-color: #ba7507; }

.border-yellow-850 {
  border-color: #935d05; }

.border-yellow-880 {
  border-color: #865931; }

.border-yellow-900 {
  border-color: #532a01; }

.border-yellow-1000 {
  border-color: #301709; }

.font-orange-50 {
  color: #fff1e8; }

.font-orange-100 {
  color: #ffd4bb; }

.font-orange-500 {
  color: #ff9000; }

.font-orange-600 {
  color: #db7c00; }

.font-orange-700 {
  color: #c44900; }

.font-orange-800 {
  color: #983800; }

.bg-orange-50 {
  background-color: #fff1e8; }

.bg-orange-100 {
  background-color: #ffd4bb; }

.bg-orange-500 {
  background-color: #ff9000; }

.bg-orange-600 {
  background-color: #db7c00; }

.bg-orange-700 {
  background-color: #c44900; }

.bg-orange-800 {
  background-color: #983800; }

.border-orange-50 {
  border-color: #fff1e8; }

.border-orange-100 {
  border-color: #ffd4bb; }

.border-orange-500 {
  border-color: #ff9000; }

.border-orange-600 {
  border-color: #db7c00; }

.border-orange-700 {
  border-color: #c44900; }

.border-orange-800 {
  border-color: #983800; }

.font-green-50 {
  color: #e7fdf4; }

.font-green-100 {
  color: #b6f9de; }

.font-green-500 {
  color: #0a8754; }

.font-green-550 {
  color: #6bd425; }

.font-green-600 {
  color: #086842; }

.bg-green-50 {
  background-color: #e7fdf4; }

.bg-green-100 {
  background-color: #b6f9de; }

.bg-green-500 {
  background-color: #0a8754; }

.bg-green-550 {
  background-color: #6bd425; }

.bg-green-600 {
  background-color: #086842; }

.border-green-50 {
  border-color: #e7fdf4; }

.border-green-100 {
  border-color: #b6f9de; }

.border-green-500 {
  border-color: #0a8754; }

.border-green-550 {
  border-color: #6bd425; }

.border-green-600 {
  border-color: #086842; }

.font-blue-50 {
  color: #e8f0f8; }

.font-blue-100 {
  color: #b9d2e9; }

.font-blue-550 {
  color: #3778c6; }

.font-blue-700 {
  color: #13293d; }

.font-blue-800 {
  color: #0f2131; }

.bg-blue-50 {
  background-color: #e8f0f8; }

.bg-blue-100 {
  background-color: #b9d2e9; }

.bg-blue-550 {
  background-color: #3778c6; }

.bg-blue-700 {
  background-color: #13293d; }

.bg-blue-800 {
  background-color: #0f2131; }

.border-blue-50 {
  border-color: #e8f0f8; }

.border-blue-100 {
  border-color: #b9d2e9; }

.border-blue-550 {
  border-color: #3778c6; }

.border-blue-700 {
  border-color: #13293d; }

.border-blue-800 {
  border-color: #0f2131; }

.font-purple-100 {
  color: #f1eaf6; }

.font-purple-550 {
  color: #a346ce; }

.font-purple-600 {
  color: #4b355f; }

.font-purple-700 {
  color: #002739; }

.bg-purple-100 {
  background-color: #f1eaf6; }

.bg-purple-550 {
  background-color: #a346ce; }

.bg-purple-600 {
  background-color: #4b355f; }

.bg-purple-700 {
  background-color: #002739; }

.border-purple-100 {
  border-color: #f1eaf6; }

.border-purple-550 {
  border-color: #a346ce; }

.border-purple-600 {
  border-color: #4b355f; }

.border-purple-700 {
  border-color: #002739; }

.font-red-100 {
  color: #fcf0f1; }

.font-red-200 {
  color: #f7d1d4; }

.font-red-550 {
  color: #db2b39; }

.font-red-600 {
  color: #c61e29; }

.font-red-700 {
  color: #af1e2a; }

.bg-red-100 {
  background-color: #fcf0f1; }

.bg-red-200 {
  background-color: #f7d1d4; }

.bg-red-550 {
  background-color: #db2b39; }

.bg-red-600 {
  background-color: #c61e29; }

.bg-red-700 {
  background-color: #af1e2a; }

.border-red-100 {
  border-color: #fcf0f1; }

.border-red-200 {
  border-color: #f7d1d4; }

.border-red-550 {
  border-color: #db2b39; }

.border-red-600 {
  border-color: #c61e29; }

.border-red-700 {
  border-color: #af1e2a; }

.font-aqua-50 {
  color: #c6f8f9; }

.font-aqua-100 {
  color: #a5f2f2; }

.font-aqua-500 {
  color: #33d6e1; }

.font-aqua-600 {
  color: #0497ae; }

.font-aqua-700 {
  color: #116e84; }

.font-aqua-800 {
  color: #083d4f; }

.font-aqua-900 {
  color: #002739; }

.bg-aqua-50 {
  background-color: #c6f8f9; }

.bg-aqua-100 {
  background-color: #a5f2f2; }

.bg-aqua-500 {
  background-color: #33d6e1; }

.bg-aqua-600 {
  background-color: #0497ae; }

.bg-aqua-700 {
  background-color: #116e84; }

.bg-aqua-800 {
  background-color: #083d4f; }

.bg-aqua-900 {
  background-color: #002739; }

.border-aqua-50 {
  border-color: #c6f8f9; }

.border-aqua-100 {
  border-color: #a5f2f2; }

.border-aqua-500 {
  border-color: #33d6e1; }

.border-aqua-600 {
  border-color: #0497ae; }

.border-aqua-700 {
  border-color: #116e84; }

.border-aqua-800 {
  border-color: #083d4f; }

.border-aqua-900 {
  border-color: #002739; }

/*Theme Colors*/
/*Borders*/
/*Borders*/
.primary-cta {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  background: linear-gradient(180deg, #33d6e1 0%, #0497ae 100%);
  border-radius: 4px; }
  .primary-cta:disabled {
    color: #fff; }
  .primary-cta:hover {
    background: linear-gradient(180deg, #2cbac4 0%, #048194 100%); }
  .primary-cta:active {
    background: linear-gradient(180deg, #33d6e1 0%, #0497ae 100%); }
  .primary-cta:disabled {
    background: #e1e1e1; }

.primary-light-cta {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0497ae;
  background: #edffff;
  border-radius: 4px; }
  .primary-light-cta:disabled {
    color: #fff; }
  .primary-light-cta:hover {
    background: #edffff; }
  .primary-light-cta:active {
    background: #edffff; }
  .primary-light-cta:disabled {
    background: #e1e1e1; }

.tertiary-cta {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0497ae; }
  .tertiary-cta:disabled {
    color: #e1e1e1; }
  .tertiary-cta:hover {
    text-decoration: underline; }
  .tertiary-cta:active {
    text-decoration: underline;
    color: #33d6e1; }

.tertiary-red-cta {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #db2b39; }
  .tertiary-red-cta:disabled {
    color: #e1e1e1; }
  .tertiary-red-cta:hover {
    text-decoration: underline; }
  .tertiary-red-cta:active {
    text-decoration: underline;
    color: #a346ce; }

.secondary-cta {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #301709;
  border-radius: 4px;
  border: 2px solid #301709;
  color: #301709;
  font-weight: 600; }
  .secondary-cta:disabled {
    color: #e1e1e1; }
  .secondary-cta:hover {
    color: #e0b808;
    border: 3px solid #e0b808; }
  .secondary-cta:active {
    border: 2px solid #f1c21b;
    box-shadow: 0px 10px 20px -10px rgba(52, 30, 72, 0.5);
    color: #f1c21b; }
  .secondary-cta:disabled {
    border: 2px solid #e1e1e1;
    background: #fff;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.25); }

.modals-component {
  background: #f8f8f9;
  mix-blend-mode: normal;
  box-shadow: 0px -10px 20px -13px rgba(0, 0, 0, 0.15);
  border-radius: 20px 20px 0px 0px; }

.input-field-box {
  background: #ffffff;
  mix-blend-mode: normal;
  border-radius: 10px; }

.input-field-box-selected {
  background: #edd486;
  mix-blend-mode: normal;
  border-radius: 10px; }

.theme-container {
  background: transparent;
  border-radius: 40px;
  padding-left: 20px;
  padding-right: 20px; }
  @media (min-width: 768px) {
    .theme-container {
      background: white; } }

.chronos-gray-card {
  background: #f8f8f8;
  box-shadow: 0px 5px 15px -10px rgba(213, 213, 213, 0.43);
  border-radius: 40px; }

.bg-white-br-9-shadow {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px; }

/*Theme Colors*/
/*Borders*/
/*Borders*/
.label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #424242;
  margin-bottom: 10px; }

.input-label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  color: #2b2b2d;
  margin-bottom: 16px;
  display: block;
  text-align: left; }
  .input-label.m-0 {
    margin-bottom: 0px; }

.components-custom-input-textarea {
  resize: none !important; }

.input-field-box-selected {
  background: #eaecf6;
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: #323232;
  font-weight: 400; }

.input-field-box-selected-dark {
  background: #323232;
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: white;
  font-weight: 700; }

.input-field-box-selected-custom {
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: white;
  font-weight: 700; }

.input-field-box-selected-blue {
  background: #1e2447;
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: white;
  font-weight: 700; }

.components-modals-marketing-index-textareautosize {
  font-size: 14px !important; }

select {
  background-image: url("./../../icons/arrow.svg") !important; }

/*Theme Colors*/
/*Borders*/
/*Borders*/
.top-nav {
  background: #c6f8f9;
  box-shadow: 0px 10px 20px -13px rgba(0, 0, 0, 0.15); }

.tapbar {
  background: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 0px -10px 20px -13px rgba(0, 0, 0, 0.15); }

.side-nav {
  background: #c6f8f9;
  background: linear-gradient(133deg, #c6f8f9 0%, #d2fcfd 100%);
  max-width: 252px;
  width: 100%; }

.menuitem {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #171718;
  border-right: 5px solid transparent;
  padding: 22px 28px; }
  .menuitem:hover {
    background: rgba(48, 23, 9, 0.1);
    color: #002739;
    background: #fff;
    border-radius: 25px 0px 0px 25px; }
  .menuitem.active {
    font-weight: 600;
    color: #002739;
    background: #fff;
    border-radius: 25px 0px 0px 25px; }

.character-card-container {
  border-radius: 10px;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.07); }

.upper-character-card {
  border-radius: 10px 10px 0px 0px; }

.upper-character-card-h1 {
  line-height: 1.05875rem; }

.character-card-li {
  line-height: 1.0625rem; }

.character-card-li-edit {
  margin-left: 20px;
  height: 17px;
  min-width: 10px;
  max-width: 10px; }

.character-card-li-icon {
  height: 17px;
  min-width: 10.31px; }

/*Theme Colors*/
/*Borders*/
/*Borders*/
.content-offering-font {
  font-size: 10px; }

.components-modals-marketing-label {
  color: #747474 !important; }

.content-idea-card {
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.2); }

.user-select-card-selected {
  background: #e9ebff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px; }

.components-modals-multi-checkcircleicon {
  color: #58ea89 !important; }

/* ------------------ React Big Calendar ------------------------ */
.rbc-calendar {
  background: #ffffff;
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  font-family: "Poppins", sans-serif; }

.rbc-time-content {
  overflow: visible !important; }

.rbc-event-label {
  display: none !important; }

.rbc-time-slot {
  border: none !important; }

.rbc-event {
  background-color: #f8f8f8 !important;
  padding: 0px !important;
  border: none !important;
  overflow: visible !important;
  position: relative !important; }

.rbc-events-container {
  border-color: #f8f8f8 !important; }

.rbc-timeslot-group {
  border-color: #f8f8f8 !important; }

.rbc-day-bg {
  border-color: #f8f8f8 !important; }

.rbc-month-view {
  border-color: #f8f8f8 !important;
  margin-top: 14px !important; }

.rbc-month-row {
  border-color: #f8f8f8 !important; }

.rbc-header {
  border-color: #f8f8f8 !important; }

.dropdown {
  margin: 0px !important;
  height: fit-content; }

.dropdown-toggle {
  padding: 0px !important;
  background-color: transparent !important;
  margin: 0px !important;
  color: #323232; }

.dropdown-menu:hover {
  background-color: transparent !important; }

.show > .btn-primary.dropdown-toggle {
  background-color: transparent !important;
  color: #323232; }

.dropdown-toggle:after {
  display: none !important; }

.rbc-header > span {
  font-weight: 700 !important;
  font-family: "Inter", sans-serif; }

.rbc-time-view {
  margin-top: -28px !important;
  border-color: #f8f8f8 !important;
  padding-right: 12px !important; }

.rbc-allday-cell {
  display: none !important; }

.rbc-label {
  color: #747474;
  font-family: "Poppins", sans-serif; }

.react-calendar {
  display: flex !important;
  flex-direction: column !important; }

.react-calendar__navigation {
  display: flex !important;
  align-items: center !important;
  padding-bottom: 20px !important; }

.react-calendar__navigation__arrow {
  margin-top: -6px !important; }

.components-modals-marketing-index-div {
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.07);
  border-radius: 25px; }

.content-offering-font {
  font-size: 10px; }

.components-modals-marketing-label {
  color: #747474 !important; }

.content-idea-card {
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.2); }

.rbc-off-range-bg {
  background-color: #f1f1f1 !important; }

.v2-view-para {
  color: #532a01; }

.v2-bg-active {
  background-color: #1e803f;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.11); }

.contentcreate-div1 {
  background: #f8f8f8;
  box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 0.07);
  border-radius: 25px !important; }

.cards-content-div {
  border-radius: 25px 25px 0px 0px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  background: #002739;
  box-shadow: 0px 10px 20px -10px rgba(73, 81, 151, 0.5); }
  .cards-content-div:disabled {
    color: #e1e1e1; }
  .cards-content-div:hover {
    font-size: 15px;
    line-height: 22px; }
  .cards-content-div:active {
    border: 2px solid #002739;
    font-size: 15px;
    line-height: 22px; }
  .cards-content-div:disabled {
    border: 1px solid #f4f4f4;
    background: #f4f4f4;
    box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 0.25); }

.cards-content-h1 {
  color: #caba2c; }

.cards-content-h1-2 {
  color: #1e7f3f; }

.cards-content-h1-3 {
  color: #532a01; }
